import { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ContentLoader from "react-content-loader"
import { useSelector } from 'react-redux';
import Config from '../../../helpers/Config';
import { makeAnyStringLengthShort, makePriceRoundToTwoPlaces } from '../../../helpers/ConversionHelper';
import { GetDefaultCurrencySymbol, getLanguageCodeFromSession, GetLocalizationControlsJsonDataForScreen, replaceLoclizationLabel } from '../../../helpers/CommonHelper';
import GlobalEnums from '../../../helpers/GlobalEnums';
import MiniCart from './MiniCart';
import { useAppDispatch } from '../../../stateManagment/reduxStore';
import rootAction from '../../../stateManagment/actions/rootAction';
import { useGetCategoriesQuery } from '../../../services/site';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ToggleContext, useSwitching } from '../../../ToggleProvider';
import { useSwitchSessionMutation } from '../../../services/auth';
import LoadingWrapper from '../products/LoadingWrapper';
import SizingBoard from './SizingBoard';
import { useLocation } from 'react-router-dom';

const DropDownMenu = ({ item, categoriesList, setCollapsed, collapsed, selectedPackageIndex, userSizingTemplate, langCode = 'en', menuRenderMode = 0, topLevelMenuItems }) => {
    const elementRef = useRef(null);
    const [height, setHeight] = useState(0);
    const [isActive, setActive] = useState('none');
    const [isActiveShop, setActiveShop] = useState(false);
    const [isActiveShopSub, setActiveShopSub] = useState(false);
    const [isDesktop, setDesktop] = useState(window.innerWidth > 992);
    const [soleLink, setSoleLink] = useState('');
    const navigate = useNavigate();

    let menuItemsQty = categoriesList?.filter(obj => obj.ParentCategoryID == item?.CategoryID)?.length;

    const resizeDropdownMenu = () => {
        if (elementRef.current == null) return;
        setHeight(elementRef.current.offsetHeight);
    };

    const updateMedia = () => {
        setDesktop(window.innerWidth > 992);
        resizeDropdownMenu();
    };

    useEffect(() => {
        if (categoriesList && categoriesList.length > 0) {
            let linkCounter = 0;
            for (let i = 0; i < categoriesList.length; i++) {
                if (categoriesList[i] && categoriesList[i].ParentCategoryID && categoriesList[i].ParentCategoryID.length > 0) {
                    var linkStr = '/' + getLanguageCodeFromSession() + '/all-products/' + categoriesList[i].ParentCategoryID + '/' + categoriesList[i].CategoryID;
                    setSoleLink(linkStr);
                    localStorage.setItem('navSoleLink', linkStr);
                    linkCounter++;
                }
            }
            if (linkCounter > 1) {
                setSoleLink('');
                localStorage.setItem('navSoleLink', '');
            }
        }
    });

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(() => {
        resizeDropdownMenu();
    }, [elementRef.current]);

    const navigateOnCategoryClick = (categoryId) => {
        setCollapsed(true);
        setActiveShop(false);
        setActiveShopSub(false);
        document.body.classList.remove('menu-open');
        setActive('none');
    }

    const toggleClass = (index) => {
        isActive === 'none' ? setActive(index) : setActive('none');
        setActiveShopSub(index);
    };

    const toggleShopClass = () => {
        setActiveShop(!isActiveShop);
    };

    const shopBackNavigation = () => {
        setActiveShop(false);
    };

    const shopBackNavigationSubmenu = () => {
        setActiveShopSub(false);
    };

    const closeMenu = () => {
        setActive('none');
        setActiveShop(false);
        setActiveShopSub(false);
        setCollapsed(!collapsed);
        if (collapsed)
            document.body.classList.add('menu-open');
        else
            document.body.classList.remove('menu-open');
    }

    const backNavigation = (index) => {
        isActive === 'none' ? setActive(index) : setActive('none');
    };

    const handleShopMenuClick = (e) => {
        if (isDesktop) {
            e.preventDefault();
            if (soleLink && soleLink.length > 0)
                navigate(soleLink);
        } else {
            if (soleLink && soleLink.length > 0) {
                navigate(soleLink);
                closeMenu();
            }
            else
                toggleShopClass();
        }
    };

    if (menuRenderMode === 0) {
        return (
            userSizingTemplate?.PackageOptions?.length > 1 && selectedPackageIndex === -1 ?
                <>
                    <li className="nav-item" title={'Please select a Package Option first.'}>
                        <span style={{ textTransform: 'uppercase' }}>Shop</span>
                    </li>
                </>
                :
                soleLink && soleLink.length > 0 ? <>
                    <li className="nav-item">
                        <Link to={soleLink} className={`${isDesktop ? 'nav-link d-md-block' : 'submenu-title nav-link'}`} onClick={handleShopMenuClick}>
                            {
                                "Shop"
                            }
                        </Link>
                    </li>
                </> : <>
                    <li className="nav-item">
                        <div className='dropdown'>
                            <Link to="#" className={`${isDesktop ? 'nav-link d-md-block' : 'submenu-title submenu-arrow-right nav-link'}`} onClick={handleShopMenuClick}>
                                {
                                    "Shop"
                                }
                            </Link>
                            <div className="dropdown-menu" ref={elementRef}>
                                <div className="dropdown-container has-custom-scrollbar pr-0 pr-md-6">
                                    <ul className={`menu-list d-md-flex shop-menu-list ${isActiveShop ? "menuListOpen" : ""}`}>
                                        <div className="close-menu clearfix d-md-none classOne">
                                            <div className="back pull-left">
                                                <button type="button" onClick={shopBackNavigation} className="btn-link btn-back" aria-label="Back to previous menu">
                                                    <span className="icon icon-arrowLeft"></span>
                                                    Back
                                                </button>
                                            </div>
                                            <div className="pull-right">
                                                <button onClick={closeMenu} className="close-button d-flex ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Close Menu">
                                                    Close
                                                    <span className="icon icon-close"></span>
                                                </button>
                                                <button onClick={closeMenu} className="close-button-overlay d-md-none" type="button"></button>
                                            </div>
                                        </div>
                                        {
                                            categoriesList?.filter(item => !item.ParentCategoryID).map((item, idx) => {
                                                return (
                                                    <li className="menu-list-item" key={item.CategoryID}>
                                                        <h6 className={`submenu-title submenu-arrow-right`} onClick={() => toggleClass(item.CategoryID)}>
                                                            {
                                                                langCode != null && langCode == Config.LANG_CODES_ENUM["Arabic"]
                                                                    ?
                                                                    (item.LocalizationJsonData != null && item.LocalizationJsonData.length > 0
                                                                        ?
                                                                        makeAnyStringLengthShort(item.LocalizationJsonData?.find(l => l.langId == Config.LANG_CODES_IDS_ENUM["Arabic"])?.text, 30)
                                                                        :
                                                                        makeAnyStringLengthShort(item.Name, 30)
                                                                    )
                                                                    :
                                                                    makeAnyStringLengthShort(item.Name, 30)
                                                            }
                                                        </h6>
                                                        <ul className={`submenu ${isActiveShopSub ? 'submenu-open' : ''} `}>
                                                            <div className="close-menu clearfix d-md-none classOne">
                                                                <div className="back pull-left">
                                                                    <button type="button" onClick={shopBackNavigationSubmenu} className="btn-link btn-back" aria-label="Back to previous menu">
                                                                        <span className="icon icon-arrowLeft"></span>
                                                                        Back
                                                                    </button>
                                                                </div>
                                                                <div className="pull-right">
                                                                    <button onClick={closeMenu} className="close-button d-flex ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Close Menu">
                                                                        Close
                                                                        <span className="icon icon-close"></span>
                                                                    </button>
                                                                    <button onClick={closeMenu} className="close-button-overlay d-md-none" type="button"></button>
                                                                </div>
                                                            </div>
                                                            <div className='subMenu_header'>
                                                                {(isDesktop ? item?.Name : isActiveShopSub)}
                                                            </div>
                                                            {categoriesList?.filter(obj => obj.ParentCategoryID == (isDesktop ? item.CategoryID : isActiveShopSub)).map((elementChild) => {
                                                                return (
                                                                    <li key={elementChild.CategoryID}>
                                                                        <Link to={`/${getLanguageCodeFromSession()}/all-products/${elementChild.ParentCategoryID}/${elementChild.CategoryID}`}
                                                                            onClick={(e) => {
                                                                                navigateOnCategoryClick(elementChild.CategoryID);
                                                                            }}
                                                                        >
                                                                            {
                                                                                langCode != null && langCode == Config.LANG_CODES_ENUM["Arabic"]
                                                                                    ?
                                                                                    (elementChild.LocalizationJsonData != null && elementChild.LocalizationJsonData.length > 0
                                                                                        ?
                                                                                        makeAnyStringLengthShort(elementChild.LocalizationJsonData?.find(l => l.langId == Config.LANG_CODES_IDS_ENUM["Arabic"])?.text, 30)
                                                                                        :
                                                                                        makeAnyStringLengthShort(elementChild.Name, 30)
                                                                                    )
                                                                                    :
                                                                                    makeAnyStringLengthShort(elementChild.Name, 30)
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='dropdown-menu-bg' style={{ height }}></div>
                    </li></>
        )
    }

    return (
        <li key={item.CategoryID} className={menuItemsQty > 10 ? "nav-item nav-item-columns four-columns" : "nav-item"}>
            <div className="dropdown">
                <Link to="#" className={`submenu-title submenu-arrow-right nav-link`} onClick={() => toggleClass(item.CategoryID)}>
                    {item?.Name}
                </Link>
                <div className="dropdown-menu" ref={elementRef}>
                    <div className="dropdown-container has-custom-scrollbar">
                        <ul className={`submenu ${isActive == item.CategoryID ? 'submenu-open' : ''} `}>
                            <div className="close-menu clearfix d-md-none classOne">
                                <div className="back pull-left">
                                    <button type="button" onClick={backNavigation} className="btn-link btn-back" aria-label="Back to previous menu">
                                        <span className="icon icon-arrowLeft"></span>
                                        Back
                                    </button>
                                </div>
                                <div className="pull-right">
                                    <button onClick={closeMenu} className="close-button d-flex ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Close Menu">
                                        Close
                                        <span className="icon icon-close"></span>
                                    </button>
                                    <button onClick={closeMenu} className="close-button-overlay d-md-none" type="button"></button>
                                </div>
                            </div>
                            <div className='d-md-none parent-header text-center'>
                                <div className={`submenu-title`}>
                                    {item.Name}
                                </div>
                            </div>
                            {
                                categoriesList?.filter(obj => obj.ParentCategoryID == item.CategoryID).map((elementChild) => {
                                    return (
                                        <li key={elementChild.CategoryID} className="menu-list-item">
                                            <Link to={`/${getLanguageCodeFromSession()}/all-products/${elementChild.ParentCategoryID}/${elementChild.CategoryID}`}
                                                onClick={(e) => {
                                                    navigateOnCategoryClick(elementChild.CategoryID);
                                                }}>
                                                {elementChild.Name}
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className='dropdown-menu-bg' style={{ height }}></div>
        </li>
    )
}

const MegaMenu = () => {
    const siteInfo = useSelector(state => state.commonReducer);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(true);
    const [CategoriesList, setCategoriesList] = useState([]);
    const loginUser = useSelector(state => state.userReducer.user);
    const { user: { SizingTemplate: userSizingTemplate, SelectedAllotmentPackageID: selectedPackageIndex } } = useSelector((state) => state.userReducer);
    const { data: categoryResponse, isLoading: CategoriesListIsLoading, isFetching: CategoriesListIsFetching } = useGetCategoriesQuery(
        { userId: loginUser?.UserID },
        {
            skip: !loginUser?.UserID,
            refetchOnMountOrArgChange: true,
        });
    const [displayLoginUserModal, setDisplayLoginUserModal] = useState(false);
    const LogoImageFromStorage = useSelector(state => state.commonReducer.websiteLogoInLocalStorage);
    const [LocalizationLabelsArray, setLocalizationLabelsArray] = useState([]);
    const [langCode, setLangCode] = useState('');
    const [menuRenderMode, setMenuRenderMode] = useState(0); //0 is for less than 3 top level menu items and 1 is for 4 or more top level menu items
    const [topLevelMenuItems, setTopLevelMenuItems] = useState(0);
    const siteName = useSelector(state => state.commonReducer.siteName);
    const { isToggled, setIsToggled } = useContext(ToggleContext);
    const [switchSession, { isLoading }] = useSwitchSessionMutation();
    const { showSwitching, hideSwitching } = useSwitching();
    const SamlLogoutEndpoint = useSelector(state => state.commonReducer.samlLogoutEndpoint);
    //const [isLoading, setIsLoading] = useState(false);
    const [introSteps] = useState([
        {
            target: '.sizing-sp-toggle-button',
            content: 'Switch between sizing and self pay mode here.',
            placement: 'bottom',
            modal: true,
            spotlight: true
        }
    ]);
    const [profileMenu, setprofileMenu] = useState(false);
    const [isMobile, setMobile] = useState(window.innerWidth < 992);
    const updateMmobileView = () => {
        setMobile(window.innerWidth < 992);
    };
    const { setIntentToSwitch } = useContext(ToggleContext);

    useEffect(() => {
        window.addEventListener("resize", updateMmobileView);
        return () => window.removeEventListener("resize", updateMmobileView);
    });

    const switchToSelfPay = () => {
        setIntentToSwitch(false);
        const Email = "NOOP";
        const Password = "NOOP";
        showSwitching();
        setIsToggled(!isToggled);
        switchSession({ Email, Password })
            .unwrap()
            .catch((error) => {
                setIsToggled(!isToggled);
                window.location.href = '/';
            })
            .finally(() => {
                window.location.href = '/';
            });
    }

    useEffect(() => {
        window.addEventListener("resize", updateMmobileView);
        return () => window.removeEventListener("resize", updateMmobileView);
    });

    useEffect(() => {
        window.addEventListener("resize", updateMmobileView);
        return () => window.removeEventListener("resize", updateMmobileView);
    });

    useEffect(() => {
        if (CategoriesListIsLoading || CategoriesListIsFetching) return;
        if (categoryResponse == null || categoryResponse == undefined) return;
        setCategoriesList(categoryResponse.respObject);
        setMenuRenderMode(categoryResponse.TopLevelMenuItems > 3 ? 1 : 0);
        setTopLevelMenuItems(categoryResponse.TopLevelMenuItems);
    }, [categoryResponse, CategoriesListIsLoading, CategoriesListIsFetching]);

    const handleOpenCloseLoginUserModal = (event) => {
        event.preventDefault();
        setDisplayLoginUserModal(!displayLoginUserModal);
    }

    const HandleLogout = (e) => {
        e.preventDefault();
        dispatch(rootAction.userAction.logOutUser());
        var noRedirect = localStorage.getItem('showUaLogin') === 'true';
        if (SamlLogoutEndpoint && SamlLogoutEndpoint.length > 5 && !noRedirect) {
            window.location.href = SamlLogoutEndpoint;
        } else {
            navigate('/');
        }
        var liveChatElem = document.getElementById('genesys-messenger');
        if (liveChatElem) {
            liveChatElem.classList.add('d-none');
        }
    }

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
        if (collapsed)
            document.body.classList.add('menu-open');
        else
            document.body.classList.remove('menu-open');
    }

    const navigateOnClick = () => {
        setCollapsed(true);
        document.body.classList.remove('menu-open');
    }

    const toggleProfileMenu = () => {
        setprofileMenu(!profileMenu);
    }

    const closeMenu = () => {
        setprofileMenu(false);
        setCollapsed(!collapsed);
        if (collapsed)
            document.body.classList.add('menu-open');
        else
            document.body.classList.remove('menu-open');
    }

    const profileMenuclickhandler = () => {
        setprofileMenu(false);
        setCollapsed(!collapsed);
        if (collapsed)
            document.body.classList.add('menu-open');
        else
            document.body.classList.remove('menu-open');
    }
    const backNavigation = (index) => {
        setprofileMenu(false);
    };

    useEffect(() => {
        let headerId = document.getElementById("header");
        let topMenuId = document.getElementById("top-menu");
        document.addEventListener("scroll", () => {
            let headerHeight = headerId?.offsetHeight + (topMenuId?.offsetHeight ?? 0);
            if (window.scrollY > headerHeight) {
                headerId.classList.add("is-stuck");
                topMenuId?.classList.add("header-is-stuck");
            } else {
                headerId.classList.remove("is-stuck");
                topMenuId?.classList.remove("header-is-stuck");
            }
        });
    }, [])

    useEffect(() => {

        const getDataInUseEffect = async () => {
            //--Get language code
            let lnCode = getLanguageCodeFromSession();
            setLangCode(lnCode);

            //-- Get website localization data
            let arryRespLocalization = await GetLocalizationControlsJsonDataForScreen(GlobalEnums.Entities["MegaMenu"], null);
            if (arryRespLocalization != null && arryRespLocalization != undefined && arryRespLocalization.length > 0) {
                setLocalizationLabelsArray(arryRespLocalization);
            }
        }
        // call the function
        getDataInUseEffect().catch(console.error);

        //--stop loader
    }, [loginUser.UserID]);

    const classOne = collapsed ? 'main-menu d-md-block out' : 'main-menu d-md-block in';

    const isCreditsVisible = () => {
        if (!loginUser.CreditEnabled || isToggled)
            return false;

        if (loginUser.CreditBalance === 0 && loginUser.PayrollDeduction)
            return false;

        return true;
    };

    const isPayrollVisible = () => {
        if (isCreditsVisible())
            return false;

        if (!loginUser.PayrollDeductionEnabled || loginUser.CreditBalance > 0 || isToggled)
            return false;

        return true;
    }

    let welcomeHeaderData = <li className="nav-item account-item d-md-none">
        <h6 className={`profile-submenu-title`} >
            <span className="user-message mr-2">
                {LocalizationLabelsArray.length > 0 ?
                    replaceLoclizationLabel(LocalizationLabelsArray, "Welcome", "lbl_account_welcome")
                    :
                    "Welcome"
                }
                &nbsp;{loginUser.FirstName}
                {isCreditsVisible() &&
                    <>
                        &nbsp;(
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "Credit available", "lbl_account_creditavailable")
                            :
                            "Credit available"
                        }
                        :&nbsp;{GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(loginUser.CreditBalance)}
                        )
                    </>
                }
                {isPayrollVisible() &&
                    <>
                        &nbsp;(
                        {LocalizationLabelsArray.length > 0 ?
                            replaceLoclizationLabel(LocalizationLabelsArray, "PD Balance", "lbl_account_pdbalance")
                            :
                            "PD Balance"
                        }
                        :&nbsp;{GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(loginUser.PayrollDeduction.AvailablePDBalance)}
                        )
                    </>
                }
            </span>
        </h6>
    </li>

    return (
        <>
            <LoadingWrapper isLoading={isLoading} />
            <header id="header" className="site-header sticky-header" role="navigation">
                <div className="position-relative">
                    <div className="d-flex container header-container">
                        <div className="navbar-header d-flex align-items-center justify-content-md-between flex-wrap w-100">
                            {
                                loginUser.UserID
                                    ?
                                    <>
                                        <button
                                            onClick={toggleNavbar}
                                            className="navbar-toggler d-flex d-md-none"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                        >
                                            <span className="icon icon-menuMobileDark"></span>
                                        </button>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                            <div className="navbar-header brand">
                                <Link to={`/${getLanguageCodeFromSession()}/`} className="ua-logo-home logo-home">
                                    <img alt="Uniform Advantage" className="logo" src="https://www.uniformadvantage.com/on/demandware.static/Sites-UA-Site/-/default/dw24faff8d/images/logo.svg" />
                                    <img alt="Uniform Advantage" className="logo-icon" src="https://www.uniformadvantage.com/on/demandware.static/Sites-UA-Site/-/default/dwe75547ea/images/logoIcon.svg" />
                                </Link>
                                <Link to={`/${getLanguageCodeFromSession()}/`} className="logo-home">
                                    {LogoImageFromStorage ?
                                        <img src={LogoImageFromStorage} alt={siteName} loading='lazy' className={siteName.replace(/[^A-Za-z\/-]/g, '-').toLowerCase()} />
                                        :
                                        <ContentLoader
                                            speed={2}
                                            width={170}
                                            height={38}
                                            viewBox="0 0 170 38"
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#ecebeb"
                                        >
                                            <rect x="0" y="0" rx="0" ry="0" width="170" height="38" />
                                        </ContentLoader>
                                    }
                                </Link>
                            </div>
                            {
                                loginUser.UserID
                                    ?
                                    <>
                                        <div className={`nav-first-actions d-flex align-items-center justify-content-end mt-md-0 ${loginUser.FirstName.length > 7 || loginUser.CreditEnabled ? 'mt-2' : ''}`}>
                                            <div className="user">
                                                <Link to={`/${getLanguageCodeFromSession()}/my-account`} className="link-nodecoration d-flex"
                                                    onClick={(e) => {
                                                        handleOpenCloseLoginUserModal(e);
                                                    }}
                                                >
                                                    <span className="user-message mr-2">
                                                        {LocalizationLabelsArray.length > 0 ?
                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Welcome", "lbl_account_welcome")
                                                            :
                                                            "Welcome"
                                                        }
                                                        &nbsp;{loginUser.FirstName}
                                                        {isCreditsVisible() &&
                                                            <>
                                                                &nbsp;(
                                                                {LocalizationLabelsArray.length > 0 ?
                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Credit available", "lbl_account_creditavailable")
                                                                    :
                                                                    "Credit available"
                                                                }
                                                                :&nbsp;{GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(loginUser.CreditBalance)}
                                                                )
                                                            </>
                                                        }
                                                        {isPayrollVisible() &&
                                                            <>
                                                                &nbsp;(
                                                                {LocalizationLabelsArray.length > 0 ?
                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "PD Balance", "lbl_account_pdbalance")
                                                                    :
                                                                    "PD Balance"
                                                                }
                                                                :&nbsp;{GetDefaultCurrencySymbol()}{makePriceRoundToTwoPlaces(loginUser.PayrollDeduction.AvailablePDBalance)}
                                                                )
                                                            </>
                                                        }
                                                    </span>
                                                    {LocalizationLabelsArray.length > 0 ?
                                                        replaceLoclizationLabel(LocalizationLabelsArray, "My Account", "lbl_account_myaccount")
                                                        :
                                                        <span className="icon icon-accountDarkV2"></span>
                                                    }
                                                </Link>
                                                <div className="popover hidden-sm-down">
                                                    <ul className="nav" role="menu" aria-label="My account navigation">
                                                        <li className="nav-item">
                                                            <Link to={`/${getLanguageCodeFromSession()}/my-account`}>
                                                                {LocalizationLabelsArray.length > 0 ?
                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "My Account", "lbl_account_myaccount")
                                                                    :
                                                                    "My Account"
                                                                }
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to={`/${getLanguageCodeFromSession()}/orders-history`}>
                                                                {LocalizationLabelsArray.length > 0 ?
                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Order History", "lbl_account_checkorder")
                                                                    :
                                                                    "Order History"
                                                                }
                                                            </Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link to="#"
                                                                onClick={(e) => {
                                                                    HandleLogout(e);
                                                                }}
                                                            >
                                                                {LocalizationLabelsArray.length > 0 ?
                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Sign Out", "lbl_account_logout")
                                                                    :
                                                                    "Sign Out"
                                                                }
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="minicart">
                                                <MiniCart />
                                            </div>
                                        </div>
                                        <div id="navbarSupportedContent" className={classOne}>
                                            {
                                                CategoriesListIsFetching || CategoriesListIsLoading ?
                                                    // true ?
                                                    <div className="w-100 container d-none d-md-block">
                                                        <ContentLoader
                                                            className='m-auto d-block'
                                                            speed={1}
                                                            width={760}
                                                            height={64}
                                                            viewBox="0 0 700 64"
                                                            backgroundColor="#f3f3f3"
                                                            foregroundColor="#ecebeb"
                                                        >
                                                            <rect x="0" y="15" rx="0" ry="10" width="160" height="35" />
                                                            <rect x="180" y="15" rx="0" ry="10" width="140" height="35" />
                                                            <rect x="340" y="15" rx="0" ry="10" width="180" height="35" />
                                                            <rect x="530" y="15" rx="0" ry="10" width="150" height="35" />
                                                        </ContentLoader>
                                                    </div>
                                                    :
                                                    <nav className="navbar navbar-expand-md">
                                                        <div className="close-menu clearfix d-md-none">
                                                            <button onClick={toggleNavbar} className="close-button d-flex ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Close Menu">
                                                                Close
                                                                <span className="icon icon-close"></span>
                                                            </button>
                                                            <button onClick={toggleNavbar} className="close-button-overlay d-md-none" type="button"></button>
                                                        </div>


                                                        <ul className="navbar-nav justify-content-md-center">
                                                            {
                                                                menuRenderMode === 0 ?
                                                                    <>
                                                                        <DropDownMenu
                                                                            categoriesList={CategoriesList}
                                                                            setCollapsed={setCollapsed}
                                                                            collapsed={collapsed}
                                                                            menuRenderMode={menuRenderMode}
                                                                            topLevelMenuItems={topLevelMenuItems}
                                                                            userSizingTemplate={userSizingTemplate}
                                                                            selectedPackageIndex={selectedPackageIndex} />
                                                                        {welcomeHeaderData}
                                                                        {isMobile && <li className="nav-item account-item">
                                                                            <Link className="nav-link" to={`/${getLanguageCodeFromSession()}/my-account`} onClick={profileMenuclickhandler}>
                                                                                {LocalizationLabelsArray.length > 0 ?
                                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "My Account", "lbl_account_myaccount")
                                                                                    :
                                                                                    "My Account"
                                                                                }
                                                                            </Link>
                                                                        </li>}
                                                                        {!isMobile && <li className="nav-item account-item">
                                                                            <Link className="nav-link" to="/update-profile"
                                                                                onClick={(e) => {
                                                                                    navigateOnClick("/update-profile", e)
                                                                                }}>
                                                                                {LocalizationLabelsArray.length > 0 ?
                                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "My Addresses", "lbl_account_myaddresses")
                                                                                    :
                                                                                    "My Addresses"
                                                                                }
                                                                            </Link>
                                                                        </li>}
                                                                        <li className="nav-item account-item">
                                                                            <Link className="nav-link" to="orders-history"
                                                                                onClick={(e) => {
                                                                                    navigateOnClick("/orders-history", e)
                                                                                }}>
                                                                                {LocalizationLabelsArray.length > 0 ?
                                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "My Orders", "lbl_account_myorders")
                                                                                    :
                                                                                    "My Orders"
                                                                                }
                                                                            </Link>
                                                                        </li>
                                                                        <li className="nav-item account-item">
                                                                            <Link className="nav-link" to="contact-us"
                                                                                onClick={(e) => {
                                                                                    navigateOnClick("/contact-us", e)
                                                                                }}>
                                                                                {LocalizationLabelsArray.length > 0 ?
                                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Help", "lbl_account_help")
                                                                                    :
                                                                                    "Help"
                                                                                }
                                                                            </Link>
                                                                        </li>
                                                                        {isMobile && <li className="nav-item account-item">
                                                                            <Link className="nav-link" to="#"
                                                                                onClick={(e) => {
                                                                                    HandleLogout(e);
                                                                                }}
                                                                            >
                                                                                {LocalizationLabelsArray.length > 0 ?
                                                                                    replaceLoclizationLabel(LocalizationLabelsArray, "Sign Out", "lbl_account_logout")
                                                                                    :
                                                                                    "Sign Out"
                                                                                }
                                                                            </Link>
                                                                        </li>}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            CategoriesList?.filter((item) => !item.ParentCategoryID).map(item =>
                                                                                <DropDownMenu key={item.CategoryID} item={item} categoriesList={CategoriesList} setCollapsed={setCollapsed} menuRenderMode={menuRenderMode} topLevelMenuItems={topLevelMenuItems} />
                                                                            )
                                                                        }
                                                                        {welcomeHeaderData}

                                                                        {isMobile &&
                                                                            <>
                                                                                <li className="nav-item account-item">
                                                                                    <Link className="nav-link" to={`/${getLanguageCodeFromSession()}/my-account`} onClick={profileMenuclickhandler}>
                                                                                        {LocalizationLabelsArray.length > 0 ?
                                                                                            replaceLoclizationLabel(LocalizationLabelsArray, "My Account", "lbl_account_myaccount")
                                                                                            :
                                                                                            "My Account"
                                                                                        }
                                                                                    </Link>
                                                                                </li>
                                                                                <li className="nav-item account-item">
                                                                                    <Link className="nav-link" to="orders-history"
                                                                                        onClick={(e) => {
                                                                                            navigateOnClick("/orders-history", e)
                                                                                        }}>
                                                                                        {LocalizationLabelsArray.length > 0 ?
                                                                                            replaceLoclizationLabel(LocalizationLabelsArray, "My Orders", "lbl_account_myorders")
                                                                                            :
                                                                                            "My Orders"
                                                                                        }
                                                                                    </Link>
                                                                                </li>
                                                                                <li className="nav-item account-item">
                                                                                    <Link className="nav-link" to="contact-us"
                                                                                        onClick={(e) => {
                                                                                            navigateOnClick("/contact-us", e)
                                                                                        }}>
                                                                                        {LocalizationLabelsArray.length > 0 ?
                                                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Help", "lbl_account_help")
                                                                                            :
                                                                                            "Help"
                                                                                        }
                                                                                    </Link>
                                                                                </li>
                                                                                <li className="nav-item account-item">
                                                                                    <Link className="nav-link" to="#"
                                                                                        onClick={(e) => {
                                                                                            HandleLogout(e);
                                                                                        }}
                                                                                    >
                                                                                        {LocalizationLabelsArray.length > 0 ?
                                                                                            replaceLoclizationLabel(LocalizationLabelsArray, "Sign Out", "lbl_account_logout")
                                                                                            :
                                                                                            "Sign Out"
                                                                                        }
                                                                                    </Link>
                                                                                </li>
                                                                            </>
                                                                        }
                                                                    </>

                                                            }
                                                        </ul>
                                                        {
                                                            <div className={`profileMenus ${profileMenu ? 'profileMenu-open' : 'profileMenu-close'} `}>
                                                                <ul className="nav" role="menu" aria-label="My account navigation">
                                                                    <li className="dropdown-item top-category" role="button">
                                                                        <span className="nav-link" role="button" aria-haspopup="false">
                                                                            <span className="user-message">Welcome {loginUser.FirstName}</span>
                                                                        </span>
                                                                    </li>
                                                                    <li className="dropdown-item account-item">
                                                                        <Link to={`/${getLanguageCodeFromSession()}/my-account`} onClick={profileMenuclickhandler}>
                                                                            {LocalizationLabelsArray.length > 0 ?
                                                                                replaceLoclizationLabel(LocalizationLabelsArray, "My Account", "lbl_account_myaccount")
                                                                                :
                                                                                "My Account"
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                    <li className="dropdown-item account-item">
                                                                        <Link to={`/${getLanguageCodeFromSession()}/orders-history`} onClick={profileMenuclickhandler}>
                                                                            {LocalizationLabelsArray.length > 0 ?
                                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Order History", "lbl_account_checkorder")
                                                                                :
                                                                                "Order History"
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                    <li className="dropdown-item account-item">
                                                                        <Link to="#"
                                                                            onClick={(e) => {
                                                                                HandleLogout(e);
                                                                            }}
                                                                        >
                                                                            {LocalizationLabelsArray.length > 0 ?
                                                                                replaceLoclizationLabel(LocalizationLabelsArray, "Sign Out", "lbl_account_logout")
                                                                                :
                                                                                "Sign Out"
                                                                            }
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        }
                                                    </nav>
                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </header>
            {
                loginUser.UserID && (!userSizingTemplate?.HasAllotmentAccess && isToggled && !userSizingTemplate?.HasAllotmentReturns && siteInfo.allotmentCompleteContinueShopping &&
                <div className='text-center mt-3'>
                    <div className="text-danger">
                        <span>{siteInfo.allotmentCompleteContinueShopping}</span> - <Link className="text-danger" onClick={switchToSelfPay}>Click Here</Link>
                    </div>
                </div>)
            }

        </>
    );

}

export default MegaMenu;